<template>
  <div class="mt-12"><h2>Sie sind jetzt erfolgreich ausgeloggt</h2></div>
</template>

<script>
  export default {
    name: "index-logout",
    components: {},

    data: function() {
      return {
        user: false,
      };
    },
    created() {},

    methods: {},
  };
</script>
